@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-cursive {
    font-family: "Dancing Script", cursive;
  }

  .font-fancy {
    font-family: "Merriweather", serif;
  }
}

body {
  font-family: "Merriweather", serif;
}
